.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #282c34;

  .wrapper {
    position: relative;
    background: #fff;
    border: 2px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 420px;
    height: 450px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    &.active {
      .form-box {
        &.login {
          transition: none;
          translate: -400px;
        }
        &.register {
          transition: translate 0.18s ease;
          translate: 0;
        }
      }
    }
    .form-box {
      &.login {
        transition: translate 0.18s ease;
        translate: 0;
      }
      width: 100%;
      padding: 40px;
      form {
        margin: 1rem;
        h1 {
          font-size: 36px;
          text-align: center;
        }
        .input-box {
          position: relative;
          width: 100%;
          height: 50px;
          margin: 30px 0;
          input {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            outline: none;
            border: 2px solid #ccc;
            border-radius: 40px;
            font-size: 16px;
            color: #000;
            padding: 20px 45px 20px 20px;
            &::placeholder {
              color: #ccc;
            }
          }
          .icon {
            position: absolute;
            right: 20px;
            top: 26px;
            translate: 0 -50%;
            font-size: 16px;
          }
        }
        .remember-forgot {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 1rem;
          font-size: 14.5px;
          margin: -15px 0 15px;
          .flex-row {
            align-items: center;
            justify-content: space-between;
          }
          label input {
            accent-color: #000;
            margin-right: 4px;
          }
          a {
            color: #000;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        button {
          width: 100%;
          height: 45px;
          background: #282c34;
          border: none;
          outline: none;
          border-radius: 40px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          font-size: 16px;
          color: #fff;
          font-weight: 700;
        }
        .register-link {
          font-size: 14.5px;
          text-align: center;
          margin: 20px 0 15px;
          p a {
            color: #000;
            text-decoration: none;
            font-weight: 600;
            margin-left: .5rem;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &.register {
        position: absolute;
        transition: none;
        translate: 400px;
      }
    }
  }
}
